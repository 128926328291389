<!-- ########################## <Template> ########################## -->
<template lang="">

    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Gestion des utilisateurs
    </div>

    <div class="pageBodyContainer"> 
        <!--  -->
        
        <!--  -->
       
       
        <div>
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id"  
                    :filters="filters" 
                    responsiveLayout="scroll" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                            <span >
                                <P-Button label="Nouvel utilisateur client" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="openAddNewItem" />
                            </span> &nbsp;
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                            </span>
                        </div>
                    </template>

                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column :exportable="false" style="width:120px">
                        <template #body="slotProps">
                            <P-Button icon="pi pi-pencil" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                 
                            <P-Button icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click="confirmDeleteSelected(slotProps.data)" />
                            <!-- <P-Button icon="pi pi-lock" class="p-button-outlined p-button-rounded p-button-help" @click="openChangePassword(slotProps.data)" /> -->
                        </template>
                    </Column>
                    <Column field="username" header="Login" :sortable="false" style="min-width:12rem"></Column>
                    <Column field="prenom" header="Prénom" :sortable="false" style="min-width:12rem"></Column>
                    <Column field="nom" header="Nom" :sortable="false" style="min-width:12rem"></Column>
                    <Column field="email" header="Email" :sortable="false" style="min-width:12rem"></Column>
                    <Column field="roles" header="Role" :sortable="false" style="min-width:8rem"></Column>
                    <Column field="dateDerniereConnexion" header="Dernière connexion" :sortable="false" style="min-width:10rem">
                        <template #body="slotProps">{{slotProps.data.dateDerniereConnexion?this.formatDatetime(slotProps.data.dateDerniereConnexion):""}}</template>
                    </Column>
                    <Column field="createur" header="Créé par" :sortable="false" style="min-width:12rem"></Column>
                    
                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                </DataTable>
            </div>

        </div>

        <!-- Delete Dialog -->
        

        <!-- Edit Dialog -->
        <Dialog v-model:visible="showEditDialogFlag" 
                    :style="{width: '800px'}" 
                    :header="(selectedGridItem.id)?'Edition':'Création d\'un nouvel utilisateur'" 
                    :modal="true" class="p-fluid"
                    @hide="closeEditDialog"
                >
            <V-Form @submit="onSubmit" :validation-schema="myValidationSchema" autocomplete="off">
            

                
                <div class="p-field">
                    <label for="username">Login</label>
                    <V-Field as="InputText" name="username" v-model.trim="selectedGridItem.username"  @change="getUserByLogin"  />
                    <span v-if="loginExists" style="color:red" >Ce login existe déjà</span>
                    <ErrorMessage name="username" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="prenom">Prénom</label>
                    <V-Field as="InputText" name="prenom" v-model.trim="selectedGridItem.prenom"   />
                    <ErrorMessage name="prenom" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="nom" class="p-mb-3">Nom</label>
                    <V-Field as="InputText" name="nom" v-model.trim="selectedGridItem.nom"   />
                    <ErrorMessage name="nom" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="email" class="p-mb-3">Email</label>
                    <V-Field as="InputText" name="email" v-model.trim="selectedGridItem.email"   />
                    <ErrorMessage name="email" class="error-feedback" />
                </div>

                <label for="jsonUserRights" class="p-mb-3">Sélection d'un modèle de role 
                    <span v-if="selectedGridItem.jsonUserRights && selectedGridItem.jsonUserRights.titre">
                    </span>
                </label>
                <Dropdown @change="changeRole" :options="frontRolesRightsdata" optionLabel="titre" :placeholder="selectedGridItem.jsonUserRights.titre?selectedGridItem.jsonUserRights.titre:'Selection du rôle'"/>

                <div class="p-field">
                    <label for="adminUtilisateursClient" tooltip="Peut administrer les clients" flow="right"  class="p-mb-3">Admin utilisateurs client</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.adminUtilisateursClient" />
                    <ErrorMessage name="adminUtilisateursClient" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="gestionBilan" tooltip="Creation des bilans et achats inforisk,Les autres peuvent visualiser" flow="right"   class="p-mb-3">Gestion bilan</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.gestionBilan" />
                    <ErrorMessage name="gestionBilan" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="GestionAcheteurs" tooltip="Tout le monde à un accés en lecture sur la liste des acheteurs" flow="right"  class="p-mb-3">Gestion acheteurs</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.gestionAcheteurs" />
                    <ErrorMessage name="GestionAcheteurs" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="scoringQualitatif" tooltip="Créer score qualitatif et affiche les recommandations du score aprés la publication" flow="right" class="p-mb-3">Création score qualitatif</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.scoringQualitatif" />
                    <ErrorMessage name="scoringQualitatif" class="error-feedback" />
                </div>
                
                <div class="p-field">
                    <label for="scoringFinancier" tooltip="Créer score financier" flow="right" class="p-mb-3">Création score financier</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.scoringFinancier" />
                    <ErrorMessage name="scoringFinancier" class="error-feedback" />
                </div>

                <div class="p-field" v-if="currentClient.activerWorkflowValidation">
                    <label for="validationQualitative" tooltip="Peut valider un score qualitatif soumis" flow="right"  class="p-mb-3">Validation qualitative</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.validationQualitative" />
                    <ErrorMessage name="validationQualitative" class="error-feedback" />
                </div>

                <div class="p-field" v-if="currentClient.activerWorkflowValidation">
                    <label for="validationFinanciere" tooltip="Peut valider un score financier soumis" flow="right"  class="p-mb-3">Validation financiere</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.validationFinanciere" />
                    <ErrorMessage name="validationFinanciere" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="scoringPublication" tooltip="Peut publier un score" flow="right"  class="p-mb-3">Publication</label>
                    <br/><InputSwitch v-model="selectedGridItem.jsonUserRights.scoringPublication" />
                    <ErrorMessage name="scoringPublication" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="userPassword"  class="p-mb-3">Mot de passe</label>
                    <V-Field  name="userPassword" v-model.trim="userPassword"  v-slot="{ field, errors, errorMessage }" autocomplete="off" >
                        <Password  v-model="userPassword"  autocomplete="off"  weakLabel="Faible" mediumLabel="Moyen" strongLabel="Fort" toggleMask>
                           
                            <template #footer="sp">
                                {{sp.level}}
                                <Divider />
                                <p class="p-mt-2">Suggestions</p>
                                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                    <li>Au moins un caractère en minuscule</li>
                                    <li>Au moins un caractère en majuscule</li>
                                    <li>Au moins un chiffre</li>
                                    <li>Minimum 8 caractères</li>
                                </ul>
                            </template>
                        </Password>
                     </V-Field>   
                    <ErrorMessage name="userPassword" class="error-feedback" />
                </div>
                
                <br/>
               <div class="p-d-flex p-jc-end">
                   
                    <button class="btn btn-danger btn-block"  @click="closeEditDialog">
                        <span>Annuler</span>
                    </button> &nbsp;
                    <button class="btn btn-primary btn-block" type="submit">
                        <span>Enregistrer</span>
                    </button>
                </div>
            
            </V-Form>
            
        </Dialog>

        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------

import AuthService from '../0-auth-components/auth.service';
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Dropdown from 'primevue/dropdown';
import Password  from 'primevue/password';

export default {
    name: "ClientClients",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        Divider,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Dropdown,
        Password
        
    },
    //--------------------------- Data --------------------------
    data() {

        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                
                username: yup.string().max(50,"Maximum 50").required("Login est obligatoire !"),
                prenom: yup.string().max(50,"Maximum 50").required("Prénom est obligatoire !"),
                nom: yup.string().max(50,"Maximum 50").required("Nom est obligatoire !"),
                email: yup.string().email("Format email n'est pas valide").max(50,"Maximum 50").required("Email est obligatoire !"),
                
        });


        return {
            gridData: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            listeDesClients: [],
            clientRoles: [
                {nom: 'Administrateur', code: 'Administrateur'},
                {nom: 'Modification', code: 'Modification'},
                {nom: 'Consultation', code: 'Consultation'},
                {nom: 'Commercial', code: 'Commercial'},
            ],
            clientRole:"Consultation",
            userPassword: "",
            frontRolesRightsdata:[],
            loginExists:0,

        } // end result
    }, //end data()
    computed: {
        currentClient()  {
            return this.$store.state.clientScoringStoreModule.currentClient;
        },
        isEditForm()
        {
            return this.selectedGridItem.id > 0 ? true:false;
        }
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- mounted()");
        this.fnLoadCurrentClient()
        this.loadGridData();

        if(!this.isAdminUtilisateursClient){
            
            this.$router.push("/client-societes");
            this.toastWarn("Vous n'avez pas le droit sur la page d'edition des utilisateurs");
        }
        
        
  }, //end mounted
    // -------- grid init
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        getUserByLogin()
        {
            var selectedId = this.selectedGridItem.id?this.selectedGridItem.id:0
            var endpoint = "getUserByLogin/"+this.selectedGridItem.username+"/"+selectedId;
            
            CRUDService.list(endpoint).then(
                (response) => {
                    debugger;
                    this.loginExists = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnLoadCurrentClient()
        {
            var endpoint = "currentclientcustom";
            
            CRUDService.get(0, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadClient reponse");
                    this.log(response.data);
                    this.$store.commit('clientScoringStoreModule/setCurrentClient', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        //========================= Manage CRUD Dialogs ===================================
        onSubmit(values, actions) {
            this.log("onSubmit");
            this.log(values);
            //manual validations
            if(this.isEditForm) {
                if(values.userPassword.length > 0)
                {
                    var r = confirm("Etes-vous sûr de vouloir modifier le mot de passe de l'utilisateur ?");
                    if (r == false) {
                        return;
                    }
                }
            }else {
                if(values.userPassword.length == 0)
                {
                    // set single field error
                    actions.setFieldError('userPassword', 'Mot de passe est obligatoire');
                    return;
                }
            }

            this.saveGridItem();
        },
        changeRole(event)
        {
            console.log(event.value)
            this.selectedGridItem.jsonUserRights = event.value
        },
        saveGridItem()
        {
            this.log("saveGridItem");
            this.log(this.selectedGridItem);

            this.selectedGridItem.jsonUserRights=this.selectedGridItem.jsonUserRights?JSON.stringify(this.selectedGridItem.jsonUserRights):''

            delete this.selectedGridItem.client

            this.selectedGridItem.application = "ClientApplication";
            this.selectedGridItem.clientId = this.currentUser.clientId;
            if(this.selectedGridItem.id > 0)
            {
                CRUDService.update(this.selectedGridItem,'userclientclient').then(
                    (response) => {
                        this.log("--------------- returned Update response.data");
                        this.log(response.data);
                        this.selectedGridItem = response.data

                        //Serializes jsonUserRights
                        
                        
                        this.toastSuccess("Utilisateur "+ this.selectedGridItem.nom +" modifié avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.selectedGridItemInitialState.jsonUserRights = response.data.jsonUserRights?JSON.parse(response.data.jsonUserRights):{}
                
                        this.showEditDialogFlag = false;
                       
                        if(this.userPassword.length > 0)
                        {
                            this.changePasswordSave(this.selectedGridItem.username,this.userPassword);
                        }
                    },
                    (error) => {
                        // debugger
                        if(error.response && error.response.data)
                        {
                            this.toastError(error.response.data);
                        }
                        else
                        {
                            this.toastError("Echec sauvegarde de l''enregistrement !");
                        }
                        
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.errorMessage = (error.response && error.response.data)?error.response.data:error.response;
                    }
                );
            }
            else
            {
                CRUDService.create(this.selectedGridItem,'userclientclient').then(
                    
                    (response) => {
                        this.log(response.data);

                        
                       
                        this.gridData.push(this.selectedGridItem);

                        
                        this.changePasswordSave(this.selectedGridItem.username,this.userPassword);

                        this.selectedGridItemInitialState = response.data;
                        this.selectedGridItemInitialState.jsonUserRights = response.data.jsonUserRights?JSON.parse(response.data.jsonUserRights):{}

                        this.showEditDialogFlag = false;
                        this.toastSuccess("Utilisateur "+ this.selectedGridItem.nom +" créé avec succès");
                    },
                    (error) => {
                        if(error.response && error.response.data)
                        {
                            this.toastError(error.response.data);
                        }
                        else
                        {
                            this.toastError("Echec sauvegarde de l''enregistrement !");
                        }
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.errorMessage = (error.response && error.response.data)?error.response.data:error.response;
                        //this.toastError("Echec création de l''enregistrement !");

                    }
                );
            }
        },
        openAddNewItem()
        {
            var clientId = this.currentUser.clientId;
            this.openEditDialog({id:0, roles:"Consultation", clientId:clientId , jsonUserRights:{}});
        },
        openEditDialog(selectedItem){
            this.loginExists = 0
            this.log("openEditDialog");
            this.openDialog(selectedItem);
        },
        openDialog(selectedItem){
            this.log("openDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;

            CRUDService.list('frontRolesRights/'+this.currentUser.clientId).then( //ActiverWorkflowValidation
                (response) => {
                    this.log(response.data);
                    this.frontRolesRightsdata = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );


            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
            this.userPassword = "";
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            
            for (let index = 0; index < this.gridData.length; index++) {
                var element = this.gridData[index];
                if(element.id == this.selectedGridItem.id)
                {
                    this.log(this.selectedGridItemInitialState);
                    this.gridData[index] = this.selectedGridItemInitialState;
                    break;
                }
            }

            this.showEditDialogFlag = false;
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le client '+this.selectedGridItem.nom+'?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        deleteGridItem()
        {
            this.log("deleteGridItem");
            CRUDService.delete(this.selectedGridItem,'userclientclient').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                    this.toastSuccess("Enregistrement supprimé avec succès");
                },
                (error) => {
                    this.toastError("Erreur suppression de l''enregisrement");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        //==================================================================================
        loadGridData()
        {
            this.log("------loadGridData");
            CRUDService.list('userclientsforclientcustom').then(
                (response) => {
                    
                    this.gridData = response.data.map(function(d){
                        var obj = {...d};
                        obj.jsonUserRights = obj.jsonUserRights?JSON.parse(obj.jsonUserRights):{}
                        return obj
                    });

                    
                    // this.log(this.gridData);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        //----------------------------------------------------------------------
        changePasswordSave: function(userLogin, password)
        {
            this.log("ClientUser.vue => changePasswordSave");
            this.log("login: "+ userLogin);
            this.log("password: "+ password);
            var user = {
                            username: userLogin,
                            password: password
                        };

            AuthService.asyncResetClientPassword(user).then(
                (response) => {
                    this.log("Reset success: " + response.data.token);
                    this.log("response.data: " + response.data);
                    this.errorMessage = "Mot de passe de '"+user.username+"' a été réinitialisé avec succès";
                    this.toastSuccess("Mot de passe de '"+user.username+"' a été mis à jour avec succès");
                },
                (error) => {
                    this.log("Reset error: " + error);
                    this.errorMessage = "ERREUR: Mot de pass de '"+user.username+"' n'a pas été réinitialisé !! "+error;
                    this.toastError("Mise à jour du mot de passe de '"+user.username+"' a échoué");
                }
            );


        },
        //=====================================================================
        handleRolesDdlValueChange(selectedDdlValue)
        {
            this.log("handleRolesDdlValueChange");
            this.selectedGridItem.roles = selectedDdlValue.value + "";
            this.log(this.selectedGridItem);
        },

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.p-rowgroup-header
{
    background-color: #e9ecef !important;
    font-size: 18px;
    font-weight: bold;
    
}
.p-rowgroup-header td {
    text-align: center !important;
};
</style>